@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;500;600;700;800;900&display=swap');


body {
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans', sans-serif;
  font-weight:500;
 
}






