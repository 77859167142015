 /* CSS for the WhatsApp button */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

/* CSS for the chat popup */
.whatsapp-popup {
    position: fixed;
    bottom: 115px;
    right: 20px;
    z-index: 9999;
    background-color: #1900CC;
    color: #fff;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
    border-radius: 10px;
    max-width: 300px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whatsapp-button:hover + .whatsapp-popup,
  .whatsapp-popup:hover {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s, 0s;
  }
  
  .whatsapp-popup p {
    margin: 0;
  }
  
  .whatsapp-popup:before {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #1900CC transparent transparent transparent;
  }